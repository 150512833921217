.header {
    display: flex;
    align-items: flex-start;
    width: 100vw;
    border-bottom: 1px solid $light-green;

    @media (min-width: $lg-mobile) {
        flex-direction: column;
        align-items: center;
    }
}

.logo {

    &__img-wrapper {
        max-width: 86px;
        max-height: 60px;
        padding: 10px;

        @media (min-width: $lg-mobile) {
            max-width: 170px;
            max-height: none;
            margin: 10px;
        }
    }

    &--pc {
        display: none;

        @media (min-width: $lg-mobile) {
            display: block;
        }
    }

    &--mobile {
        display: block;

        @media (min-width: $lg-mobile) {
            display: none;
        }
    }
}

.menu {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    @media (min-width: $lg-mobile) {
        flex-direction: row;
        justify-content: center;
    }

    &__icon {
        width: 30px;
        height: 30px;
        padding: 15px;
        color: $green;
        cursor: pointer;

        @media (min-width: $lg-mobile) {
            display: none;
        }
    }

    &__list {
        display: none;
        flex-direction: column;
        width: 100%;
        font-family: $link;

        &.active {
            display: flex;
        }

        @media (min-width: $lg-mobile) {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }

    &__item {
        width: 100%;
        text-align: center;

        @media (min-width: $lg-mobile) {
            width: auto;
        }
    }

    &__link {
        display: block;
        
        // padding: 3px 0 3px;
        border-bottom: 1px solid transparent;
        margin-left: -86px;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 200;
        color: $green;
        text-decoration: none;
        // transition: $transition-time;

        &.active {
            // background-color: #eee;
            // border: 1px solid black;
            font-weight: 400;
            
            @media (min-width: $lg-mobile) {
                border-bottom: 1px solid black;
                
            }
        }
        
        &:hover {
            // background-color: #eee;
            // border: 1px solid black;
            @media (min-width: $lg-mobile) {
                border-bottom: 1px solid black;
                
            }
            border-bottom: 1px solid black;
            font-weight: 400;
        }
        
        @media (min-width: $lg-mobile) {
            width: 120px;
            margin-left: 0;
            line-height: 60px;
        }

        @media (min-width: $sm-pc) {
            width: 150px;
            line-height: 60px;
        }
    }
}
