$dark-green: #1c3103;
$green: #435711;
$light-green: #dbe67f;
$lighter-green: #f5f8dc;



$yellow: #fcf186;
$orange: #f0af01;
$red: #cf5b5b;

$link: 'Nunito Sans', sans-serif;
$content: 'Nunito Sans', sans-serif;
$title: 'Nunito Sans', sans-serif;

$sm-mobile: 400px;
$md-mobile: 520px;
$lg-mobile: 780px;
$sm-pc: 1024px;
$md-pc: 1366px;
$lg-pc: 1920px;

$transition-time: 0.2s;

// font-family: 'Nunito Sans', sans-serif;
// font-family: 'Poiret One', cursive;