.contact {
    padding-top: 100px;

    &__title {
        padding: 0 30px;
        margin-bottom: 30px;
        text-align: center;
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @media (min-width: $md-mobile) {
            flex-direction: row;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        margin: 0 20px 30px;

        &:nth-last-of-type(1) {
            margin-bottom: 0;
        }
        
        @media (min-width: $md-mobile) {
            margin: 0 20px;
        }
    }

    &__icon {
        font-size: 30px;
        margin-bottom: 10px;
    }

    &__info-title {
        font-family: $title;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 200;
    }

    &__info-text {
        text-align: center;
        font-weight: 200;
    }
}