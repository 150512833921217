.footer {
    &__section {
        display: flex;
        flex-direction: column;
        padding: 20px;
        
        &:nth-of-type(1) {
            border-bottom: 1px solid $green;
        }
        &:nth-of-type(2) {
            padding-bottom: 0;
        }

        &:nth-last-of-type(1) {
            padding: 0 20px 20px;
        }

        &--center {
            justify-content: center;
            // gap: 20px;
        }

        &-item {
            flex-basis: 50%;
        }

        @media (min-width: $lg-mobile) {
            flex-direction: row;
            // justify-content: space-between;
        }

    }
    // align-items: flex-end;
    background-color: $light-green;
    font-family: $content;

    &__info {
        font-weight: 200;
        color: lighten($green, 6%);
        font-size: 16px;

        // &:nth-last-of-type(1) {
        //     margin-bottom: 30px;
        // }
    }

    &__copy {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 200;
    }
    
    &__social {
        order: -1;
        // margin-bottom: 30px;
        @media (min-width: $lg-mobile) {
            order: 1;
        }
    }

    &__title {
        margin-bottom: 10px;
        color: $green;
        font-size: 16px;
        font-weight: normal;
        text-transform: uppercase;
    }

    &__social-media {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    &__icon {
        width: 22px;
        height: 22px;
        padding: 0 6px 0 0;
        color: lighten($green, 6%);
        transition: $transition-time;

        &:hover {
            color: lighten($green, 15%);
        }
    }

    &__links {
        // text-align: center;
    }

    &__link {
        padding: 0 10px;
        border-right: 1px solid $dark-green;
        font-size: 13px;
        color: $dark-green;
        text-decoration: none;

        &:nth-last-of-type(1) {
            border-right: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}