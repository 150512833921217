.pagination {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 30px;

    &__btn {
        padding: 10px 20px;
        border-bottom: 2px solid rgba(28, 49, 3, 0.1);
        color: $dark-green;
        cursor: pointer;

        &--current {
            border-bottom: 2px solid $dark-green;
        }

        &--large {
            display: none;
            @media (min-width: 600px) {
                display: block;
            }
        }
    }
}