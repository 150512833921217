.not-found {
    position: relative;

    &__content {
        position: absolute;
        left: 50%;
        top: 36%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 60px);
        max-width: 400px;
    }

    &__icon {
        margin-bottom: 10px;
        color: $light-green;
        font-size: 60px;
    }

    &__text {
        text-align: center;
        font-family: $content;
        font-size: 18px;
        font-weight: normal;
        color: $dark-green;
    }
}