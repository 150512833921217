.search {
    display: block;
    width: 100vw;
    padding: 23px 30px;
    border: none;
    border-bottom: 1px solid $light-green;
    outline: none;
    font-family: $link;
    font-size: 16px;
    font-weight: 200;
    text-transform: uppercase;

    &::placeholder {
        color: rgba(28, 49, 3, 0.8);
    }
}