.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100vw - 60px);
    max-width: 800px;
    margin: 0 auto;

    &__inp {
        display: block;
        flex-basis: 100%;
        padding: 6px 10px;
        margin: 0 0 20px;
        outline: none;
        font-family: $content;
        
        @media (min-width: 500px) {
            flex-basis: calc(50% - 10px);
        }
    }
    
    &__textarea {
        display: block;
        flex-basis: 100%;
        min-height: 220px;
        padding: 6px 10px;
        margin: 0 0 20px;
        font-family: $content;
        resize: vertical;
        outline: none;
    }
    
    &__submit {
        display: block;
        flex-basis: 100%;
        padding: 6px;
        border: 1px solid black;
        border-radius: 2px;
        font-family: $content;
        color: #eee;
        background-color: $light-green;
        outline: none;
        cursor: pointer;
        transition: 0.2s;
        
        &:disabled {
            background-color: rgba(156,170,100, 0.5);
            color: #999;
            cursor: default;
            

            &:hover {
                background-color: #bbccba;
                color: #999;
            }
        }

        &:hover {
            color: #111;
        }
    }

    &__response {
        padding: 10px;
        border-radius: 2px;
        margin: 10px 0 0 0;
        flex-basis: 100%;
        font-size: 14px;
    }

    &__validation {
        &-list {
            flex-basis: 100%;
            padding: 10px 0;
        }

        &-item {
            padding: 0;
            margin: 0;
            font-size: 12px;
        }
    }
}