.filter {
    position: relative;
    padding: 15px 30px;
    border-bottom: 1px solid $light-green;

    &__title {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        padding: 0 10px;
        color: $green;
        background-color: #fff;
        font-family: $content;
        font-size: 16px;
        font-weight: 200;
        text-transform: uppercase;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;

        @media (min-width: 600px) {
            justify-content: flex-start;
        }
    }

    &__item {
        transform: translateX(100px);
        padding: 4px 16px;
        color: $green;
        background-color: #f5f8dc;
        font-family: $content;
        font-weight: 200;
        font-size: 14px;
        text-transform: uppercase;
        opacity: 0;
        cursor: pointer;
        transition: $transition-time;
        animation: slide cubic-bezier(0.280, 0.355, 0.290, 1.270) 0.4s forwards;
        @for $i from 1 through 100 {
            &:nth-child(#{$i}n) {
                animation-delay: #{$i * 0.1 - 0.1}s;
            }
        }

        &.checked {
            background-color: $light-green;
            // color: #eee;
        }

        @media (min-width: 600px) {
            padding: 6px 20px;
            font-size: 15px;
        }
        @media (min-width: 1024px) {
            padding: 8px 30px;
            font-size: 16px;
        }
    }
}