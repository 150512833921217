.galery {

    &__list {
        display: flex;
        flex-wrap: wrap;
        // justify-content: flex;
        padding: 15px 15px 0;
    }

    &__item {
        position: relative;
        flex-basis: calc(100% - 30px);
        aspect-ratio: 1/1;
        margin: 15px;
        background-color: rgba(180, 180, 180, 0.5);
        overflow: hidden;
        transition: 0.3s;
        animation: pulse infinite linear 2s;

        &>a>.galery__curtain:hover {
            opacity: 1;
        }

        @media (min-width: 600px) {
            flex-basis: calc(50% - 30px);
        }

        @media (min-width: 1200px) {
            flex-basis: calc(33.333333% - 30px);
        }
    }

    &__curtain {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        opacity: 0;
        transition: 0.3s;
    }

    &__title {
        font-family: $title;
        font-weight: 900;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 20px;
        color: #eee;
        background-color: rgba(0,0,0,0.2);

        &--picture {
            font-size: 22px;
        }
    }
}