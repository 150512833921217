.popup {
    &-overlay {
        background-color: rgba(255,255,255,0);
        animation: show-popup 0.3s ease forwards;
    }

    &-arrow {
        color: $green;
        stroke: $green;
        stroke-width: 4px;
        transform: translateX(-8px);
    }
}

.normal-popup {
    &-content {
        padding: 14px;
        border-bottom: 2px solid $green;
        background-color: black;
        text-align: center;
        font-weight: bold;
    }
}

.lightbox {
    &-overlay {
        background-color: rgba(245,245,245,0.8);
        animation: none;
    }
}